/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 10, 2019 */



@font-face {
    font-family: 'bebas_neuebold';
    src: url('bebasneue_bold-webfont.woff2') format('woff2'),
         url('bebasneue_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuebook';
    src: url('bebasneue_book-webfont.woff2') format('woff2'),
         url('bebasneue_book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuelight';
    src: url('bebasneue_light-webfont.woff2') format('woff2'),
         url('bebasneue_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neueregular';
    src: url('bebasneue_regular-webfont.woff2') format('woff2'),
         url('bebasneue_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuethin';
    src: url('bebasneue_thin-webfont.woff2') format('woff2'),
         url('bebasneue_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}